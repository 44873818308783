import axios from 'axios';
import router from '../router'; // 假设你已经设置了vue-router

let baseURL;
if(process.env.VUE_APP_SERVER === 'development') {
    baseURL = 'http://127.0.0.1:9090';
} else if(process.env.VUE_APP_SERVER === 'production') {
    baseURL = 'https://yunxikeji.com.cn';
}

// 创建一个axios实例
const instance = axios.create({
    baseURL: baseURL, // 你的API的基础URL
    timeout: 10000,
});

// 请求拦截器
instance.interceptors.request.use(
    async config => {
        // 获取存储在本地的token
        const userInfoToken = localStorage.getItem('userInfoToken');
        const expirationTimeStr = localStorage.getItem('expirationTime'); // 这是一个字符串
        // 将字符串转换为数值
        const expirationTime = parseInt(expirationTimeStr, 10);
        // 检查Token是否过期
        if (Date.now() >= expirationTime) {
            const newAccessToken = await refreshAccessToken();
            // 更新配置中的访问令牌
            config.headers['Authorization'] = 'Bearer ' + newAccessToken;
            // 如果Token过期，则移除存储的token信息
            localStorage.removeItem('userInfoToken');
            localStorage.removeItem('expirationTime');
            // 判断当前页面是否已经是登录页面
            console.log("当前页面" + router.app.$route.path);
            if (router.app.$route.path !== '/login') {
                // 如果不在登录页面，则跳转到登录页面
                router.push('/login');
            }
            return Promise.reject('token过期');
        }
        if (userInfoToken) {
            config.headers['Authorization'] = 'Bearer ' + userInfoToken;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 设置响应拦截器
axios.interceptors.response.use(response => {
    return response;
}, error => {
    // 如果响应是401，则清除Token并跳转到登录页面
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('userInfoToken');
        localStorage.removeItem('expirationTime');
        // 检查当前路由是否已经是登录页面
        if (router.app.$route.path !== '/login') {
            router.push('/login');
        }
    }
    return Promise.reject(error);
});



// 定义一个函数来刷新令牌
async function refreshAccessToken() {
    try {
        // 从本地存储中获取刷新令牌
        const userInfoToken = localStorage.getItem('userInfoToken');
        // 发送请求到后端的刷新令牌接口
        const response = await axiosInstance.post('/api/user/refreshToken', null, {
            headers: {
                'refreshToken': userInfoToken
            }
        });
        // 存储新的访问令牌
        localStorage.setItem('userInfoToken', response.data.accessToken);
        // 返回新的访问令牌
        return response.data.accessToken;
    } catch (error) {
        // 如果刷新令牌失败，重定向到登录页面
        localStorage.removeItem('userInfoToken');
        localStorage.removeItem('expirationTime');
        if (router.app.$route.path !== '/login') {
            router.push('/login');
        }
        throw new Error('Token expired');
    }
}



export default instance;