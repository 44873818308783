<template>
  <div id="app"  @mousedown="windowMove(true)" @mouseup="windowMove(false)">
    <Home></Home>
  </div>
</template>


<script>
import Home from './view/home.vue'
import { isPc } from '@/util/util.js'

export default {
  name: 'App',
  components: {
    Home
  },
  methods:{
    windowMove(canMove){
      if (isPc()){
        import("electron").then(({ ipcRenderer }) => {
          ipcRenderer.send('window-move-open', canMove ?? false);
        });
      }
    },
  }
}
</script>
<!-- import JavaScript -->
<!--<script src="https://unpkg.com/element-ui/lib/index.js"></script>-->
<style lang="scss">
.iconfont {
  font-style: normal;
  font-size: 25px;
  vertical-align: middle;
  color: rgb(117,120,137);
  transition: .3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  padding: 0;
  margin: 0;
  //font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
#app {
  width: 100vw;
  height: 100vh;
  background-size:cover;
  position:absolute;
}
</style>
