import VueRouter from 'vue-router'
import ChatHome from '../view/pages/chatHome/index.vue'
import Login from "@/view/pages/chatHome/login";

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes: [{
        path: "*",
        redirect: "/login",
    }, {
        path: "/login",
        name: "Login",
        component: Login,
    }, {
        path: "/ChatHome",
        name: "ChatHome",
        component: ChatHome,
    }
    ]
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 检查Token是否有效
    const userInfoToken = localStorage.getItem('userInfoToken');
    const expirationTimeStr = localStorage.getItem('expirationTime'); // 这是一个字符串
    // 将字符串转换为数值
    const expirationTime = parseInt(expirationTimeStr, 10);
    // 如果Token过期或者不存在，允许访问登录页面
    if (!userInfoToken || Date.now() >= expirationTime) {
        if (to.path === '/login') {
            return next();
        }
        return next('/login');
    }
    // 如果Token有效，阻止访问登录页面
    if (to.path === '/login') {
        return next(false); // 或者 next(from.path) 或者 next('/')
    }
    // 其他情况，允许访问
    next();
});
export default router;
